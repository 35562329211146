* {
  margin: 0px;
  padding: 0px;
}

.border {
  border: 1px solid red;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}